<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="7.5"
    viewBox="0 0 24 7.5"
  >
    <path
      :fill="fillBorder"
      transform="translate(-0.69 1.16)"
      d="M9.17,5.17,6.34,2.34A8,8,0,0,0,.69,0H23.31a8,8,0,0,0-5.65,2.34L14.83,5.17A4,4,0,0,1,9.17,5.17Z"
    />

    <path
      :fill="fill"
      transform="translate(-0.69 1.16)"
      d="M9.17,4.19,6.34,1.26A7.86,7.86,0,0,0,.69-1.16H23.31a7.86,7.86,0,0,0-5.65,2.42L14.83,4.19A3.9,3.9,0,0,1,9.17,4.19Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconsTooltipTip',

  props: {
    fillBorder: {
      type: String,
      default: '#ffffff'
    },

    fill: {
      type: String,
      default: '#ffffff'
    }
  }
}
</script>
