<template>
  <div v-if="show || alwaysShow">
    <div
      class="tooltip relative rounded empty:hidden text-xs leading-[1.125rem] border w-full z-99"
      :class="[
        appendPopoverClass,
        component.main,
        {
          'mb-[0.375rem]': !tipInSide && tipY === 'bottom',
          'mt-[0.375rem]': !tipInSide && tipY === 'top',
          'ml-[0.375rem]': tipInSide && tipX === 'left',
          'mr-[0.375rem]': tipInSide && tipX === 'right'
        }
      ]"
    >
      <IconsClose
        v-if="hasClose"
        class="absolute hover:cursor-pointer"
        :class="component.closePosition"
        type="circle"
        :fill="component.closeIconFill"
        :width="component.closeIcon"
        :height="component.closeIcon"
        @click="handleClose"
      />

      <template v-if="component.icon && hasIcon">
        <IconsClose
          v-if="component.icon.icon === 'close-cirle'"
          :width="component.icon.width"
          :height="component.icon.height"
          type="circle"
        />

        <IconsInfoCircle
          v-else-if="component.icon.icon === 'info'"
          :width="component.icon.width"
          :height="component.icon.height"
          type="outline"
          fill="#fff"
        />

        <NuxtImg
          v-else-if="component.icon.icon === 'clock'"
          :src="getImageUrl(`/icons/${component.icon.icon}.svg`)"
          :width="component.icon.width"
          class="place-self-center"
        />
      </template>

      <p
        v-if="$slots.title"
        class="tooltip__title text-sm empty:hidden mb-2 font-bold"
        :class="`${hasClose ? 'pr-[1.625rem]' : ''}`"
      >
        <slot name="title" />
      </p>

      <slot v-if="$slots.default" />

      <div
        ref="tooltip-arrow"
        class="tooltip-arrow"
        :class="component.tipPosition"
      >
        <AtomsTooltipArrow
          :fill="component.tipColors.bg"
          :fill-border="component.tipColors.border"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
defineOptions({
  name: 'AtomsTooltip'
})

const emits = defineEmits(['close', 'update:modelValue'])

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },

  type: {
    type: String,
    default: 'default',
    validator: value => ['default', 'call-hours', 'info', 'error', 'dark', 'purple', 'success'].includes(value)
  },

  size: {
    type: String,
    default: 'default',
    validator: value => ['default', 'small'].includes(value)
  },

  tipX: {
    type: String,
    default: 'center',
    validator: value => ['left', 'center', 'right'].includes(value)
  },

  tipXAdjustment: {
    type: String,
    default: ''
  },

  tipXRightClass: {
    type: String,
    default: ''
  },

  tipY: {
    type: String,
    default: 'bottom',
    validator: value => ['top', 'bottom', 'middle'].includes(value)
  },

  tipYAdjustment: {
    type: String,
    default: ''
  },

  tipInSide: {
    type: Boolean,
    default: false
  },

  hasClose: {
    type: Boolean,
    default: false
  },

  smallClose: {
    type: Boolean,
    default: false
  },

  hasIcon: {
    type: Boolean,
    default: true
  },

  alwaysShow: {
    type: Boolean,
    default: false
  },

  /**
   * @params {String}
   * @value 2md, md, lg
   */
  radius: {
    type: String,
    default: ''
  },

  appendPopoverClass: {
    type: String,
    default: ''
  }
})

// variables
const show = proxyModel(props, 'modelValue')

const component = computed(() => {
  const icon = getKey(props.type, {
    default: {
      icon: 'close-cirle',
      width: 13.33,
      height: 13.33
    },
    'call-hours': {
      icon: 'clock',
      width: 12.94,
      height: 13.14
    },
    error: {
      icon: 'info',
      width: 13.33,
      height: 13.33
    },
    info: false,
    dark: false,
    success: {
      icon: 'info',
      width: 13.33,
      height: 13.33
    }
  })

  const closeIcon = props.smallClose
    ? 16
    : 20

  const closePosition = props.smallClose
    ? 'top-[0.333125rem] right-[0.333125rem]'
    : 'top-[0.625rem] right-[0.625rem]'

  const color = getKey(props.type, {
    default: {
      bg: 'bg-white',
      border: 'border-white'
    },
    'call-hours': {
      bg: 'bg-alert-info-fill',
      border: 'border-alert-info-stroke'
    },
    error: {
      bg: 'bg-alert-error-stroke',
      border: 'bg-alert-error-stroke'
    },
    dark: {
      bg: 'bg-dark',
      border: 'border-dark'
    },
    purple: {
      bg: 'bg-purple-70',
      border: 'border-purple-70'
    },
    success: {
      bg: 'bg-primary',
      border: 'border-primary'
    }
  })

  const main = (() => {
    const size = props.size === 'small' && ['default', 'error'].includes(props.type)
      ? 'p-1'
      : 'p-2'

    let map = getKey(props.type, {
      default: 'font-semibold text-gray-80 !drop-shadow-sm-4 drop-shadow-4-fr',
      dark: 'font-semibold text-white !drop-shadow-sm-2',
      info: 'text-dark !drop-shadow-sm-3',
      'call-hours': 'text-dark !drop-shadow-md-1',
      error: 'text-white !drop-shadow-sm-2',
      purple: 'font-semibold text-white !drop-shadow-sm-4 drop-shadow-4-fr',
      success: 'font-semibold text-dark !drop-shadow-sm-2'
    })

    if (icon && props.hasIcon) {
      map += ` inline-grid grid-cols-[1rem,auto] items-center ${props.type === 'error' ? 'gap-1' : 'gap-2'}`
    } else {
      map += ' inline-block align-top'
    }

    map += ` ${color.bg} ${color.border}`

    return `${map} ${size}`
  })()

  const tipXAdjustment = Number(`${props.tipXAdjustment}`.replace(/px/, ''))

  const tipPosition = (() => {
    const map = {
      top: '-top-[0.4375rem] rotate-180',
      right: 'right-1',
      bottom: '-bottom-[0.4375rem]',
      left: 'left-1',
      center: '-translate-x-1/2 left-1/2',
      middle: 'translate-y-[-50%] top-1/2'
    }

    if (props.type === 'call-hours') {
      map.right = 'right-[0.875rem]'
    } else if (props.type === 'error') {
      map.bottom = '-bottom-[0.39375rem]'
    }

    if (props.tipInSide) {
      map.left = '-left-[15px] rotate-90'
      map.right = '-right-[15px] -rotate-90'
      map.bottom = 'bottom-[0.875rem]'
      map.top = 'top-[0.875rem]'
    }

    if (props.tipXRightClass && !props.tipInSide) {
      map.right = props.tipXRightClass
    }

    const tip = props.$refs?.['tooltip-arrow']
    if (tip) {
      if (['bottom', 'top'].includes(props.tipY)) {
        if (tipXAdjustment > 0) {
          tip.style.left = `${tipXAdjustment}px`
          tip.style.transform = `
                translateX(0)
                rotate(${props.tipY === 'top' ? '180deg' : '0'})
              `
        } else {
          tip.style.left = '50%'
          tip.style.transform = `
                translateX(-50%)
                rotate(${props.tipY === 'top' ? '180deg' : '0'})`
        }
      }
    }

    return `absolute ${map[props.tipX]} ${map[props.tipY]}`
  })()

  const tipColors = getKey(props.type, {
    default: {
      bg: '#ffffff',
      border: '#ffffff'
    },
    'call-hours': {
      bg: '#eff9ff',
      border: '#74ceff'
    },
    error: {
      bg: '#c50e0e',
      border: '#ffffff'
    },
    dark: {
      bg: '#193560',
      border: '#193560'
    },
    purple: {
      bg: '#552692',
      border: '#552692'
    },
    success: {
      bg: '#0ac16a',
      border: '#0ac16a'
    }
  })

  const closeIconFill = props.type === 'default' ? '#193560' : '#e2e4e6'

  return {
    icon,
    color,
    main,
    tipPosition,
    tipColors,
    closeIcon,
    closePosition,
    closeIconFill
  }
})

// onMounted(() => {
//   if (props.hasClose) {
//     show.value = true
//   } else {
//     show.value = props.modelValue || true
//   }
// })

// functions
const handleClose = () => {
  show.value = false
  emits('close')
}
</script>
